import { ICategory } from "@/src/lib";
import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

type Props = {
  category: ICategory;
  lng: string;
};

export const CategorySlide: FC<Props> = ({
  category: {
    backgroundColor,
    image,
    text,
    textColor,
    name,
    titleColor,
    buttonBackgroundColor,
    buttonText,
    buttonTextColor,
    slug,
  },
  lng,
}) => {
  return (
    <div
      className="w-full flex flex-col md:flex-row md:h-96 px-6 mt-64 md:mt-0"
      style={{
        background: backgroundColor,
      }}
    >
      <div className="md:w-1/2 flex justify-center">
        <div className="absolute top-12 w-60 md:w-auto max-w-[312px] overflow-hidden">
          {image && (
            <Image
              src={image?.url}
              alt={image.alternativeText}
              width={312}
              height={451}
              style={{ width: "100%", height: "auto" }}
            />
          )}
        </div>
      </div>
      <div className="relative pt-40 md:pt-12 pb-6 flex flex-col gap-4 justify-center md:justify-between w-full md:w-1/2">
        <h3 className="text-2xl md:text-4xl" style={{ color: titleColor }}>
          {name}
        </h3>
        <p
          className="md:pr-12 text-sm md:text-base"
          style={{
            color: textColor,
          }}
        >
          {text}
        </p>
        <Link href={`/${lng}/kategorija/${slug}?page=1`}>
          <button
            className="md:w-1/2 py-2 rounded-sm"
            style={{
              background: buttonBackgroundColor,
              color: buttonTextColor,
            }}
          >
            {buttonText}
          </button>
        </Link>
      </div>
    </div>
  );
};
