"use client";
import { Iframe as IframeProps } from "@/src/types";
import { type FC } from "react";

import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

type Props = IframeProps & {};

export const Iframe: FC<Props> = ({ source }) => {
  const video = JSON.parse(source)?.url;

  if (!video) return null;
  return (
    <div className="w-full py-6">
      <ReactPlayer
        width={"100%"}
        height={'500px'}
        url={JSON.parse(source).url}
        stopOnUnmount
        controls={true}
      />
    </div>
  );
};
