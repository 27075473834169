"use client";

import { FC, useState } from "react";
import { Form } from "./Form";

type Props = {};

export const SendQuery: FC<Props> = () => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <button onClick={() => setModal(true)}>Send Query</button>
      <Form
        onClose={() => setModal(false)}
        title="title"
        buttonText="button tx"
        label={{
          email: "email",
          firstName: "fisrt",
          lastName: "lastn",
          message: "msg",
        }}
        open={modal}
      />
    </>
  );
};
