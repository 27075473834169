import { Cms } from "@/src/utils";
import { CmsQuery } from "@/src/utils";
import { IHashtag, IHashtagPageData, ISingleHashtagPageData } from "./types";
import { PagedResult } from "@/src/utils";

const RES_KEY = "/hashtags";
const RES_KEY_HASHTAG_PAGE = "/hashtag-page";
const RES_KEY_SINGLE_HASHTAG_PAGE = "/single-hashtag-page";

export const getHashtags = async (
  query?: CmsQuery
): Promise<PagedResult<IHashtag>> => {
  const client = Cms.createClient();
  return await client.fetchContentEntries(RES_KEY, { ...query });
};

export const getHashtag = async (query: CmsQuery): Promise<IHashtag> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY, {
    ...query,
    populate: "*",
  });
};

export const getHashtagPageData = async (
  query: CmsQuery
): Promise<IHashtagPageData> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_HASHTAG_PAGE, {
    ...query,
    populate: "*",
  });
};

export const getSingleHashtagPageData = async (
  query: CmsQuery
): Promise<ISingleHashtagPageData> => {
  const client = Cms.createClient();

  return await client.fetchContentEntry(RES_KEY_SINGLE_HASHTAG_PAGE, {
    ...query,
    populate: "*",
  });
};
