"use client";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC } from "react";
import { Input } from "../common/Input";
import { z } from "zod";
import { Modal } from "../common/Modal";

const FormSchema = z.object({
  firstName: z.string().min(1, { message: "Name is required" }),
  lastName: z.string().min(1, { message: "Name is required" }),
  email: z.string().email(),
  message: z.string().min(1, { message: "Name is required" }),
});

export type ValuesType = z.infer<typeof FormSchema>;

type TFormProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  buttonText: string;
  label: {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
  };
};

export const Form: FC<TFormProps> = ({
  open,
  title,
  buttonText,
  label,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
    resolver: zodResolver(FormSchema),
    mode: "onChange",
  });
  const sendMail = async (values: ValuesType) => {
    await fetch("/api/email?", {
      method: "POST",
      body: JSON.stringify(values),
    });
  };

  return (
    <Modal title={title} onClose={onClose} open={open}>
      <form
        onSubmit={handleSubmit((data) => {
          sendMail(data);
        })}
      >
        <div className="mb-6 flex flex-col gap-4">
          <Input
            label={label.firstName}
            inputProps={{ ...register("firstName"), placeholder: "first name" }}
          />
          <Input
            inputProps={{ ...register("lastName"), placeholder: 'last name' }}
            label={label.lastName}
          />
          <Input inputProps={{ ...register("email"), placeholder: "email" }} label={label.email} />
          <Input
            label={label.message}
            textareaProps={{
              className: "max-h-40 min-h-16",
              ...register("message"),
              placeholder: "message"
            }}
          />
          <button
            type="submit"
            className="w-full rounded bg-primary px-3 py-2 text-sm font-semibold text-white hover:text-white hover:bg-gray-400 transition-all duration-150"
          >
            {buttonText}
          </button>
        </div>
      </form>
    </Modal>
  );
};
