"use client"
import { Media as MediaProps } from "@/src/types";
import Image from "next/image";
import { useState, type FC } from "react";
import dynamic from "next/dynamic";
const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

type Props = MediaProps & {};

export const Media: FC<Props> = ({ source, type }) => {
  const [controls, setControls] = useState(false);

  if(!source.url) return null

  if (type === "image") {
    return (
      <Image
        src={source.url}
        alt={source.alternativeText}
        width={844}
        height={599}
        className="py-6"
        style={{ width: "100%", height: "auto" }}
      />
    );
  } else {
    return <div className="w-full py-6" onMouseEnter={() => setControls(true)} onMouseLeave={() => setControls(false)}>
      <ReactPlayer width={"100%"} url={source.url} stopOnUnmount controls={controls} playing muted loop />
    </div>
  }
};
