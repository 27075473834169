"use client";
import type { ICategory } from "@/src/lib/category/types";
import { SwiperSlide } from "swiper/react";
import { Controller, Mousewheel, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import Image from "next/image";
import { type FC, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import dynamic from "next/dynamic";
import { CategorySlide } from "./CategorySlide";

const Swiper = dynamic(() => import("swiper/react").then((mod) => mod.Swiper), {
  ssr: false,
});

interface ICategoryList {
  categories: ICategory[];
  title: string;
  isSinglePage?: boolean;
  activeCategory?: string;
  lng: string;
}

const CategorySlider: FC<ICategoryList> = ({ categories, lng }) => {
  const paginationRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Swiper
        direction="horizontal"
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        className="w-full pb-20 relative md:h-[calc(100% - 80px)]"
        modules={[Controller, Mousewheel, Pagination, Navigation]}
        pagination={{
          el: ".pagination-custom",
          type: "bullets",
          clickable: true,
          bulletClass: "category-pagination-bullet",
          bulletActiveClass: "category-pagination-bullet-active",
        }}
        mousewheel={{ enabled: true, releaseOnEdges: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
      >
        {categories?.map(
          (category) => {
            return (
              <SwiperSlide key={category.id}>
                <CategorySlide category={category} lng={lng} />
              </SwiperSlide>
            );
          }
        )}
        <div className="w-full flex items-center md:justify-end h-10 md:h-40">
          <div
            ref={paginationRef}
            className="w-full md:w-1/2 flex flex-wrap gap-3 pagination-custom border-red-400"
          />
        </div>
        <div className="absolute bottom-0 top-0 right-0 h-full md:h-96 w-0 z-10 flex flex-col justify-center items-end">
          <button className="swiper-button-prev bg-white flex justify-center items-center w-8 h-8">
            <ChevronLeftIcon width={20} height={20} />
          </button>
          <button className="swiper-button-next bg-white  flex justify-center items-center w-8 h-8">
            <ChevronRightIcon width={20} height={20} />
          </button>
        </div>
      </Swiper>
    </>
  );
};

export { CategorySlider };
