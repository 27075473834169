"use client";
import { useQuery } from "@tanstack/react-query";
import { CmsQuery } from "@/src/utils";
import { getHashtags } from "./service";

export const useHashtags = ({
  filter,
  enabled = true,
}: {
  filter: CmsQuery;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: ["hashtags", filter],
    queryFn: async () => {
      const { entries, pagination } = await getHashtags(filter);
      return { hashtags: entries, pagination };
    },
    enabled: enabled,
  });

